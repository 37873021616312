<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/demandFulfillmentProhibitionService'
import locale from '../locales/en.js'
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)

      helpers.updateOptionsRefs(
        ctx.centerOriginOptions,
        ctx.centersOriginByCenterTypeOrigin
      )

      helpers.updateOptionsRefs(ctx.customerOptions, ctx.customersByCustomerVIP)

      const fieldsById = _.keyBy(meta.fields, (f) => f.id)
      
      fieldsById.centerTypeOrigin.options = ctx.centerTypeOriginOptions
      fieldsById.centerOrigin.options = ctx.centerOriginOptions
      fieldsById.customer.options = ctx.customerOptions
      fieldsById.carrier.options = ctx.carrierOptions
      fieldsById.customerVIP.options = fieldsById.customerVIP.options.filter(
        (option) =>
          option === locale.application.enums.yesOrNo.yes.toLowerCase() ||
          option === locale.application.enums.yesOrNo.no.toLowerCase()
      )

      fieldsById.purchaseContractCompany.onValueChange = function(changedValue, fields) {
        updatePurchaseContractOptions(fields);
        updatePurchaseContractCakOptions(fields);
      }
      fieldsById.purchaseContractCropYear.onValueChange = function(changedValue, fields) {
        updatePurchaseContractOptions(fields);
        updatePurchaseContractCakOptions(fields);
      }

      fieldsById.saleContractCompany.onValueChange = function(changedValue, fields) {
        updateSaleContractOptions(fields);
        updateSaleContractCakOptions(fields);
      }
      fieldsById.saleContractCropYear.onValueChange = function(changedValue, fields) {
        updateSaleContractOptions(fields);
        updateSaleContractCakOptions(fields);
      }

      //Purchase

      fieldsById.purchaseContract.onValueChange = (
        changedValue,
        fields
      ) => {
        const purchaseContractCakOptions = getPurchaseContractCakOptionsBySplit(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContractCak,
          fields.purchaseContractCak,
          purchaseContractCakOptions
        )
      }
      
      const updatePurchaseContractCakOptions = (fields) => {
        const purchaseContractCakOptions = getPurchaseContractCakOptions(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContractCak,
          fields.purchaseContractCak,
          purchaseContractCakOptions
        )
      }

      const getPurchaseContractCakOptions = (fields) => {
        var result = ctx.purchaseContractCakOptions.map((x) => x.label)
        if (fields.purchaseContractCompany.value != null) {
          var fieldCompany =
            typeof fields.purchaseContractCompany.value === 'object'
              ? fields.purchaseContractCompany.value.value
              : fields.purchaseContractCompany.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.purchaseContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.purchaseContractCropYear.value
          )
        }    
        result = result.map(x => x.contractReference)
        return _.orderBy(result)
      }

      const getPurchaseContractCakOptionsBySplit = (fields) => {
        var result = ctx.purchaseContractCakOptions.map((x) => x.label)    
        if (fields.purchaseContract.value != null && fields.purchaseContract.value.label != null) {
          result = result.filter(
            (x) => x.contractReference == fields.purchaseContract.value.label.slice(0, Math.min(6, fields.purchaseContract.value.label.length))
          )
        }
        result = result.map(x => x.contractReference)
        return _.orderBy(result)
      }

      const updatePurchaseContractOptions = (fields) => {
        const purchaseContractOptions = getPurchaseContractOptions(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContract,
          fields.purchaseContract,
          purchaseContractOptions
        )
      }

      const getPurchaseContractOptions = (fields) => {
        var result = ctx.purchaseContractOptions.map((x) => x.label)
        if (fields.purchaseContractCompany.value != null) {
          var fieldCompany =
            typeof fields.purchaseContractCompany.value === 'object'
              ? fields.purchaseContractCompany.value.value
              : fields.purchaseContractCompany.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.purchaseContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.purchaseContractCropYear.value
          )
        }
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }

      fieldsById.purchaseContractCak.onValueChange = (
        changedValue,
        fields
      ) => {
        const purchaseContractOptions = getPurchaseContractOptionsByCak(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContract,
          fields.purchaseContract,
          purchaseContractOptions
        )
      }

      const getPurchaseContractOptionsByCak = (fields) => {
        var result = ctx.purchaseContractOptions.map((x) => x.label)
        if (fields.purchaseContractCak.value != null) {
          result = result.filter(
            (x) => x.contractReference.startsWith(fields.purchaseContractCak.value)
          )
        }
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }
      //end Purchase

      //Sale

      fieldsById.saleContract.onValueChange = (
        changedValue,
        fields
      ) => {
        const saleContractCakOptions = getSaleContractCakOptionsBySplit(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.saleContractCak,
          fields.saleContractCak,
          saleContractCakOptions
        )
      }
      
      const updateSaleContractCakOptions = (fields) => {
        const saleContractCakOptions = getSaleContractCakOptions(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.saleContractCak,
          fields.saleContractCak,
          saleContractCakOptions
        )
      }

      const getSaleContractCakOptions = (fields) => {
        var result = ctx.saleContractCakOptions.map((x) => x.label)
        if (fields.saleContractCompany.value != null) {
          var fieldCompany =
            typeof fields.saleContractCompany.value === 'object'
              ? fields.saleContractCompany.value.value
              : fields.saleContractCompany.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.saleContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.saleContractCropYear.value
          )
        }    
        result = result.map(x => x.contractReference)
        return _.orderBy(result)
      }

      const getSaleContractCakOptionsBySplit = (fields) => {
        var result = ctx.saleContractCakOptions.map((x) => x.label)    
        if (fields.saleContract.value != null && fields.saleContract.value.label != null) {
          result = result.filter(
            (x) => x.contractReference == fields.saleContract.value.label.slice(0, Math.min(6, fields.saleContract.value.label.length))
          )
        }
        result = result.map(x => x.contractReference)
        return _.orderBy(result)
      }

      const updateSaleContractOptions = (fields) => {
        const saleContractOptions = getSaleContractOptions(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.saleContract,
          fields.saleContract,
          saleContractOptions
        )
      }

      const getSaleContractOptions = (fields) => {
        var result = ctx.saleContractOptions.map((x) => x.label)
        if (fields.saleContractCompany.value != null) {
          var fieldCompany =
            typeof fields.saleContractCompany.value === 'object'
              ? fields.saleContractCompany.value.value
              : fields.saleContractCompany.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.saleContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.saleContractCropYear.value
          )
        }
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }

      fieldsById.saleContractCak.onValueChange = (
        changedValue,
        fields
      ) => {
        const saleContractOptions = getSaleContractOptionsByCak(fields)
        helpers.updateOptionsObject(
          this,
          fieldsById.saleContract,
          fields.saleContract,
          saleContractOptions
        )
      }

      const getSaleContractOptionsByCak = (fields) => {
        var result = ctx.saleContractOptions.map((x) => x.label)
        if (fields.saleContractCak.value != null) {
          result = result.filter(
            (x) => x.contractReference.startsWith(fields.saleContractCak.value)
          )
        }
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }

      //end Sale

      const getCenterOriginOptions = (value) =>
        value?.value == null
          ? ctx.centerOriginOptions
          : ctx.centersOriginByCenterTypeOrigin[value.value]

      fieldsById.centerTypeOrigin.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.centerOrigin,
          fields.centerOrigin,
          getCenterOriginOptions(changedValue)
        )
      }

      const getCustomerOptions = (value) =>
        value == null ? ctx.customerOptions : ctx.customersByCustomerVIP[value]

      fieldsById.customerVIP.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.customer,
          fields.customer,
          getCustomerOptions(changedValue)
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const localityFields = helpers.getLocalityFields()
        const localityOriginFields = {}
        localityFields.forEach(
          (field) => (localityOriginFields[field] = field + 'Origin')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityOriginFields
        )
        const localityDestinationFields = {}
        localityFields.forEach(
          (field) => (localityDestinationFields[field] = field + 'Destination')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityDestinationFields
        )

        return yup.object().shape(validationSchema)
      }

      return meta
    }

    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
